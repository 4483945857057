.template-card {
    height: 100%;
    border-radius: 20px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.06);
    flex: 1;
    margin: 10px; 
    box-sizing: border-box;
    min-width: 200px;
    max-width: 460px;

    a {
        text-decoration: none;
    }

    button {
        border: none;
    }

    img {
        width: 100%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .template-content {
        padding: 16px;
        
        &-title {
            font-weight: 700;
        }
        
        .template-text {
            height: 70px;
            margin-bottom: 32px;
            & > span {   
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                line-clamp: 4;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                word-wrap: break-word;
                font-size: 14px;
                font-weight: 500;
            }            
        }
    }
    
    .template-image {
        width: 100%;
        height: 420px;
        object-fit: cover;        
    }

    .template-prime-link {
        padding: 10px;
        background-color: rgba(89, 200, 87, 1);
        text-decoration: none;
        color: white;
        display: flex;
        justify-content: center;
        text-align: center;
        font-weight: 600;
        border-radius: 12px;
    }

    .template-secondary-link {
        padding: 10px;
        border: 1px solid rgba(89, 200, 87, 1);
        color: rgba(89, 200, 87, 1);
        display: flex;
        justify-content: center;
        text-align: center;
        font-weight: 600;
        margin-top: 10px;
        border-radius: 12px;
    }

    .a-group {
        display: flex;     
    
        .pdf {
            margin-right: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(89, 200, 87, 0.1);
            border-radius: 10px;
            height: 40px;
            color: rgba(89, 200, 87, 1);
            font-size: 10px;
            width: 100%;
            height: 55px;
            padding: 0 15px;
            margin: 0;
            
            .pdf-icon-container {
                width: 24px;
                margin-right: 6px;
                & > #spinner {                    
                    width: 1.2rem;
                    height: 1.2rem;
                    margin-top: 2px;
                }
            }
            
            img {
                max-width: 24px;                
            }                  
        }

        .order-now {
            width: 100%;
            border: 1px solid rgba(89, 200, 87, 1);
            border-radius: 12px;
            color: rgba(89, 200, 87, 1);
            height: 55px;
            align-items: center;
            display: flex;
            justify-content: center;
        }      
    }
}

@media screen and (max-width: 585px) {
    .template-card {
        width: 100%
        img {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1050px) {
    .template-card {
        .template-image {
            height: 580px;
        }
        .template-content {
            .a-group {
                display: block;
                
                .pdf {
                    width: 100%;
                }
            }
        }
    }
}
