.switch-billing-plan-wrapper {
  padding-right: 20px;
  padding-left: 20px;
  display: inline-block;

  .MuiSwitch-track {
    height: 26px;
    border-radius: 13px;
    margin-top: -6px;
    margin-left: -6px;
  }

  .Mui-checked {
    color: #FFFFFF !important;
  }

  .Mui-checked+.MuiSwitch-track {
      background-color: #7a8082 !important;
    opacity: 0.5;
  }

  .MuiSwitch-track {
    background-color: #7a8082 !important;
    opacity: 0.5;
  }

  .MuiSwitch-root {
    width: 70px;
  }
}

.select-currency-wrapper {
  span {
    padding-right: 30px;
  }

  .MuiInputBase-root {
    background: #4CAC44;
    border: none;
    color: #FFFFFF;
    height: 33px;
    border-radius: 17px;
    outline: none;
    font-size: 15px;
  }
  .MuiSelect-select {
    padding-right: 50px !important;
  }
  .MuiSelect-icon {
    color: #FFFFFF;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}