.billingPlan {
    &Mobile {
        & > h3 {
            font-size: 16px;
        }
    }
}
.planAnnual {
    margin-bottom: 20px;
    padding: 16px;
    border-radius: 16px;
    background-color: var(--main-green);
    color: white;
    min-width: 300px;

    :global .name-separate {
        color: #59C857
    }

    &Mobile {
        min-width: initial;
        padding: 8px;
        font-size: 14px;
        & p {
            margin: 0px !important;
        }        
        & > h2 {
            font-size: 19px;
        }
        & > h3 {
            font-size: 18px;
        }
        & hr {
            margin: 4px 0px;
        }
        & button {
            padding: 8px !important;            
        }
    }
    & button {
        outline: none;
        background-color: white;
        padding: 16px;
        width: 100%;
        border-radius: 16px;
        color: var(--main-green);
        border: none;
        margin-top: 8px;
    }
}

.planMonthly {
    @extend .planAnnual;
    filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.06));
    background: #FFFFFF;
    color: initial;

    &Mobile {
        @extend .planAnnualMobile;
    }
    & button {
        border: 1px solid var(--main-green);
    }
}
.bold {
    font-weight: 700;    
}

.greenCardPlan {
    background: var(--main-green);
    color: #FFFFFF;

    :global .name-separate {
        color: #192D45;
    }
}
