.imprint {
    width: 80%;
    margin: 80px auto;
    text-align: justify;

    .logo {
        display: inline-block;
        margin: 20px 0 50px;
    }

    & > .block {
        margin-bottom: 50px;
        font-size: 20px;
    }  

    & .title {        
        font-weight: 700;  
        font-size: 22px;      
    }    

    & .info {
        & > span {
            &:first-of-type {
                color: #7a8082;
                margin-right: 10px;
            }            
        }        
    }    
}