.skip-btn {
    margin-bottom: 10px;
}

.goals-title {
    font-size: 18px;
    margin-top: 0;
}

label {
    font-size: 14px;
}
