.pop-modal {
    min-height: 160px;
    width: 360px;
    background-color: white;
    border-radius: 20px;
    position: absolute;
    top: 80px;
    right: 40px;
    z-index: 999;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin-top: 10px;

    .user {
        display: flex;
        align-items: center;
        border-bottom: 1px solid silver;
        padding-bottom: 10px;

        img {
            width: 57px;
            height: 57px;
            margin-right: 16px;
        }

        &-data {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 10px;

            .green-text {
                cursor: pointer;
            }
        }
    }

    .logout {
        display: flex;
        align-items: center;
        padding-top: 10px;
        cursor: pointer;

        img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }
    }
}
.overflow-ellipsis {
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}