:root {
  --main-bg: #fff;
  --grey-font: #8E8E93;
  --main-green: #59C857;
  --light-grey-bg: #F1F1F1;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

select {
    display: block;
    width: 100%;
    font-size: 1em;
    padding: 0.8rem 0.5rem;
    border: 1px solid #333;
    font-family: inherit;
    /** for the dropdown indicator */
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

.App {
  min-height: 100vh;
  overflow-x: hidden;
 
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-green);
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: var(--light-grey-bg);
}

#logo {
  max-width: 100px;
}


/* TOPBAR */
.topbar {
  min-height: 79px;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: var(--main-bg);
  z-index: 6;
}

.topbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.log-in-out {
  color: var(--grey-font);
  display: flex;
  align-items: center;
  cursor: pointer;
}

#log-icon {
  margin-bottom: 16px;
  margin-left: 8px;
  width: 24px;
  height: 24px;
}


/* LANDING CLIENT */
.landing-client {
  min-height: 80vh;
  max-height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100vw;
  background-color: var(--main-bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 110px;
}

.landing-top {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 100vw;
}

.anima-clouds {
  min-height: 20vh;
  min-width: 100vw;
  animation: slideIn 6s ease-out;
  position: absolute;
  left: 0;
  z-index: 0;
  background-repeat: no-repeat;
  height: 50px;

}

@media screen and (max-width: 585px) {
  .anima-clouds {
    top: 25%;
  }
}

.anima-cloud {
  display: none;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    /* Начальное смещение вправо */
  }

  to {
    transform: translateX(0);
    /* Конечное смещение влево */
  }
}

.cta-headline {
  font-weight: 700;
  color: black;
  text-align: center;
  z-index: 999;
  /* line-height: 140%; */
  font-size: 48px;
  position: relative;
  z-index: 2;
}

.green-text {
  color: var(--main-green);
}

.grey-link {
  color: var(--grey-font);
  text-decoration: none;
}
@media screen and (max-width: 585px) {
  .grey-link {
    font-size: 12px;
    font-weight: 400;
  }
}
.landing-center {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-image: url('/src/assets/bg-main-desktop.svg');
  /* background-position-x: 14%; */
  background-position-y: bottom;
  background-size: cover;
  background-repeat: repeat-x;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -188px
}

.landing-stat-block {
  width: 342px;  
  background-color: var(--light-grey-bg);
  padding: 13px 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  z-index: 4;  
}
@media screen and (max-width: 390px) {
  .landing-stat-block {
    width: 90%;    
  }
}

.bold-font {
  font-weight: bold;
}

.arrow-right-icon {
  font-size: 24px;
}

.stones {
  max-height: 120px;
  position: relative;
  top: 70px;
  z-index: 999;
}

.anima-main {
  width: 100vw;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 0;  
}
@media screen and (max-width: 585px) {
  .anima-main-mobile {
    width: 100vw;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    margin-top: 23.5dvh;

  }

}
.anima-main-mobile-short{
  width: 100vw;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  margin-top: 20dvh;

}

#hero-tree {
  position: absolute;
  bottom: -10%;
  z-index: 1;
  max-height: 50vh;
  opacity: 0;
  object-position: bottom;
}

#hero-tree.animate-zoom {
  animation: zoomIn 3s ease-out;
  opacity: 1;
  transform-origin: bottom;
}

@keyframes zoomIn {
  from {
    transform: scaleY(0);
    transform: scale(0);
    /* Начальный размер - 0% */
  }

  to {
    transform: scaleY(1);
    transform: scale(1);
    /* Конечный размер - 100% */
  }
}

@keyframes slideInBoy {
  from {
    left: -100%;
  }

  to {
    left: 36%;
  }
}

@keyframes reverseBoy {
  from {
    left: 36%;
  }

  to {
    left: 31%;
  }
}

#hero-boy {
  position: absolute;
  bottom: -45px;
  left: 36%;
  height: 180px;
  z-index: 999;
}

#hero-boy.slidein-boy {
  animation: slideInBoy 3s ease-out;
}

#hero-boy.reverse-boy {
  animation: reverseBoy 3s ease-out;
  left: 31%;
}


@keyframes mesleftBack {
  from {
    left: 36%;
  }

  to {
    left: 31%;
  }
}

.message-left {
  width: 40px;
  position: absolute;
  left: 36%;
  top: -20px;
  opacity: 0;
  z-index: 9999;
}

.message-left.animate-zoom {
  animation: zoomIn 3s ease-out;
  opacity: 1;
  transform-origin: bottom;
}

.message-left.message-left-back {
  animation: mesleftBack 3s ease-out;
  left: 31%;
  opacity: 1;
}

@keyframes slideInGirl {
  from {
    right: -100%;
  }

  to {
    right: 36%;
  }
}

@keyframes reverseGirl {
  from {
    right: 36%;
  }

  to {
    right: 33%;
  }
}

#hero-girl {
  position: absolute;
  bottom: -10%;
  right: 36%;
  bottom: -67px;
  height: 220px;
  z-index: 999;
}

#hero-girl.slidein-girl {
  animation: slideInGirl 3s ease-out;
}

#hero-girl.reverse-girl {
  animation: reverseGirl 3s ease-out;
  right: 33%;
}

@keyframes mesrightBack {
  from {
    right: 35%;
  }

  to {
    right: 32%;
  }
}

.message-right {
  width: 40px;
  position: absolute;
  right: 35%;
  top: -22px;
  opacity: 0;
  z-index: 9999;
}

.message-right.animate-zoom {
  animation: zoomIn 3s ease-out;
  opacity: 1;
  transform-origin: bottom;
}

.message-right.message-right-back {
  animation: mesrightBack 3s ease-out;
  right: 32%;
  opacity: 1;
}

.poor-icon {
  /* background-image: url('/src/assets/poor.svg');
  background-position: center;
  background-repeat: no-repeat; */
  color: rgb(242, 86, 116);
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.good-icon {
  /* background-image: url('/src/assets/good.svg');
  background-position: center;
  background-repeat: no-repeat; */
  width: 100px;
  color: rgb(89, 200, 87);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.landing-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 30px;
}

.landing-bottom-mobile{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.vote-buttons {
  display: flex;
  gap: 62px;
  margin-bottom: 10px;
  height: 130px;
  width: 100%;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
}

.vote-buttons div {
  min-width: 72px;
  cursor: pointer;
}

.click-btn {
  margin-bottom: 5px;
}

/* Sign in Redirect */
.sign-redirect {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.sign-redirect img {
  margin-bottom: 16px;
}

.google-sign img {
  margin-right: 16px;
  margin-bottom: 0;
}

.sign-redirect .google-sign {
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: var(--main-green);
  color: white !important;
  border: 1px solid var(--main-green);
  border-radius: 4px;
  padding: 8px 20px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 20px;
}

.sign-redirect p {
  max-width: 400px;
  text-align: center;
  color: var(--grey-font);
}

.sign-redirect .google-sign:hover {
  color: white !important;
  background-color: #59c857ce;
  transition: all 0.15s ease-in-out;
}

.link-back {
  color: var(--main-green);
  margin-top: 16px;
}

.link-back:hover {
  color: black !important;
  transition: all 0.15s ease-in-out;
  
}


/* POOR REVIEW */
.review-poor-page {
  position: fixed;
  background-color: white;
  z-index: 7;
  width: 100vw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.review-close-block {
  position: absolute;
  top: 32px;
  right: 32px;
}

.review-close-block img {
  cursor: pointer;
  z-index: 99999;
}

.review-form {
  max-width: 400px;
  padding: 8px;
}

.review-form-description {
  text-align: center;
}

.review-form-description p {
  color: var(--grey-font);
}

.review-form input,
.review-form textarea {
  border: none;
  padding: 12px;
  background-color: #F1F1F1;
  width: 100%;
  /*margin-bottom: 16px;*/
  border-radius: 8px;
  outline: none;
}

.rate-star {
  height: 24px;
  cursor: pointer;
}

.star-gold {
  box-shadow: 0 0 60px inset gold;
}

.submit-review {
  background-color: #CCCCCC;
  width: 100%;
  outline: none;
  color: white;
  padding: 16px 0;
  text-align: center;
  border: none;
  border-radius: 8px;
}

.active-btn {
  background-color: black;
}

.active-btn-green {
  background-color: var(--main-green);
}

/* Modal */
.modal-screen {
  min-height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.234);
  backdrop-filter: blur(12px);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.modal-pane {
  width: 320px;
  text-align: center;
  background-color: white;
  padding: 16px;
  border-radius: 16px;
}

.modal-pane p {
  color: var(--grey-font);
}

/* Heroboard */
.heroboard {
  height: 100vh;
  position: relative;
}

.hero-header {
  display: flex;
  justify-content: space-around;
  padding: 16px;
  min-height: 90px;
}

.hero-title {
  text-align: center;
}

.hero-title p {
  color: var(--grey-font);
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60vh;
  overflow-y: scroll;
}

.hero-content {
  width: 100%;
  padding: 16px;
  max-width: 400px;
}

.review-item {
  border-bottom: 1px solid var(--grey-font);
  padding: 12px 0;
}

.review-name {
  color: var(--main-green);
  font-size: 14px;
}

.review-stars {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
}

.comment {
  font-size: 14px;
}

.company-name {
  font-size: 12px;
  color: var(--grey-font);
}

.user-img {
  margin-right: 12px;
  border-radius: 100%;
  height: 48px;
  width: 48px;
}

.comment-date {
  font-size: 14px;
  color: var(--grey-font);
}

/* BUSINESS REGISTRATION */

.reg-wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.goals-wrapper {
  min-height: 80vh;
  max-width: 368px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin-top: 64px;

}

.goals-list {
  min-width: 368px;
  max-width: 400px;
  padding: 20px;
}

.address-div {
  /* min-width: 368px;
  max-width: 400px; */
  padding: 20px;
  position: relative;
}

.address-list {
  position: absolute;
  top: 72px;
  left: 0px;
  padding: 16px 8px;
  background-color: white;
  border: 1px solid var(--grey-font);
  border-top: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  max-height: 260px;
  overflow-y: scroll;
  box-shadow: 0 4px 2px silver;
  width: 100%;
}

.address-item {
  border-bottom: 1px solid var(--grey-font);
  padding: 6px 0;
  cursor: pointer;
}

.goal-item {
  padding: 10px 20px;
  display: flex;
  gap: 16px;
  align-items: center;
  border: 1px solid var(--grey-font);
  border-radius: 12px;
  margin-bottom: 10px;
}

.goal-item input {
  outline: none;
}

.select-search {
  border: none;
  padding: 16px;
  background: #F1F1F1;
  width: 100%;
  border-radius: 16px;
  outline: none;
}


.plan-item-annual {
  margin-bottom: 20px;
  padding: 16px;
  border-radius: 16px;
  background-color: var(--main-green);
  color: white;
  min-width: 360px;
}

.plan-item-monthly {
  border: 1px solid var(--grey-font);
  margin-bottom: 20px;
  padding: 16px;
  border-radius: 16px;
  min-width: 360px;
}

.plan-item-annual button {
  outline: none;
  background-color: white;
  padding: 16px;
  width: 100%;
  border-radius: 16px;
  color: var(--main-green);
  border: none;
  margin-top: 8px;
}

.plan-item-monthly button {
  outline: none;
  background-color: white;
  padding: 16px;
  width: 100%;
  border-radius: 16px;
  color: var(--main-green);
  border: 1px solid var(--main-green);
  margin-top: 8px;
}

.create-progress-mobile {
  display: none;
}

.mobile-route-item {
  width: 12px;
  height: 12px;
  cursor: default;
}

.create-progress {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 24px;
  width: 100%;
  height: 72px;
  border-bottom: 1px solid var(--grey-font);
}

.route-item {
  text-align: center;
  padding: 8px 20px;
  margin-bottom: 0;
  cursor: default;
}

.route-active {
  color: var(--main-green);
  border-bottom: 2px solid var(--main-green);
  padding: 8px 20px;
}

.billing-plans {
  display: flex;
}


.account-top-bar {
  padding: 28px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.account-rating {
  color: white;
  background-color: var(--main-green);
  padding: 12px;
  border-radius: 12px;
  margin-right: 20px;
  display: flex;
  gap: 5px;
}

.notification {
  position: relative;
  margin-right: 20px;
}

.indicator {
  position: absolute;
  top: 0;
  right: 0;
}

.active-component-container {
  padding: 8px 20px;
}

.table-head,
.review-row {
  display: flex;
  align-items: flex-start;
  font-size: 0.75vw;
  border-bottom: 1px solid var(--grey-font);
  padding-bottom: 8px;
}

.review-row:hover {
  cursor: pointer;
}

.table-head .col,
.review-row .col {
  max-width: 160px;
}

/* .active-component-name {
  min-width: 45vw;
} */

.mobile-topbar-container {
  display: none;
}

.mobile-navbottom {
  display: none;
}

.mobile-topbar {
  background-color: rgb(255, 255, 255, 0.4);
  min-height: 90px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
}

.mobile-topbar-right {
  display: flex;
  align-items: center;
  gap: 20px;
}


.modal-content {
  border-radius: 20px !important;
  z-index: 999999;
}

.billing-plan-component {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.invoices-block {
  width: 60%;
  padding-right: 40px;
  position: relative;
}

.invoices-details {
  /* padding-right: 40px; */
  min-height: 445px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.billing-plan-block {
  width: 40%;
  min-height: 545px;
  margin: 0;
  padding: 0;
}

.billing-details {
  padding-right: 40px;
  background-color: rgba(142, 142, 147, 0.08);
  padding: 20px;
  border-radius: 20px;
  min-height: 445px;
  margin-top: 32px;
}

.invoice-table {
  min-height: 20vh;
  width: 100%;
}

.invoice-table-head {
  display: flex;
  font-size: 1vw;
  /* padding: 8px; */
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--grey-font);
  width: 40vw !important;
}

.invoice-row {
  display: flex;
  width: 40vw !important;
  padding: 10px 0;
  font-size: 0.85vw;
  align-items: center;
  border-bottom: 1px solid var(--grey-font);
}

.invoice-col-title {
  color: var(--grey-font);
  font-weight: 600 !important;
  font-size: 12px;
}

@media screen and (max-width: 1048px) {

  .create-progress-mobile {
    height: 72px;
    background-color: white;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    border-bottom: 1px solid var(--grey-font);
    z-index: 10000;
  }

  .create-progress {
    display: none;
  }

  #hero-tree {
    position: absolute;
    bottom: -10%;
    z-index: 1;
    max-height: 44vh;
    opacity: 0;
    object-position: bottom;
  }

  #hero-tree.animate-zoom {
    animation: zoomIn 3s ease-out;
    opacity: 1;
    transform-origin: bottom;
  }

  @keyframes zoomIn {
    from {
      transform: scaleY(0);
      transform: scale(0);
      /* Начальный размер - 0% */
    }

    to {
      transform: scaleY(1);
      transform: scale(1);
      /* Конечный размер - 100% */
    }
  }

  @keyframes slideInBoy {
    from {
      left: -100%;
    }

    to {
      left: 25%;
    }
  }

  @keyframes reverseBoy {
    from {
      left: 25%;
    }

    to {
      left: 20%;
    }
  }

  #hero-boy {
    position: absolute;
    bottom: -50px;
    left: 25%;
    height: 180px;
    z-index: 999;
  }

  #hero-boy.slidein-boy {
    animation: slideInBoy 3s ease-out;
  }

  #hero-boy.reverse-boy {
    animation: reverseBoy 3s ease-out;
    left: 20%;
  }

  @keyframes slideInGirl {
    from {
      right: -100%;
    }

    to {
      right: 26%;
    }
  }

  @keyframes reverseGirl {
    from {
      right: 26%;
    }

    to {
      right: 22%;
    }
  }

  #hero-girl {
    position: absolute;
    bottom: -10%;
    right: 26%;
    bottom: -70px;
    height: 220px;
    z-index: 999;
  }

  #hero-girl.slidein-girl {
    animation: slideInGirl 3s ease-out;
  }

  #hero-girl.reverse-girl {
    animation: reverseGirl 3s ease-out;
    right: 22%;
  }

  @keyframes mesleftBack {
    from {
      left: 26%;
    }

    to {
      left: 20%;
    }
  }

  .message-left {
    width: 40px;
    position: absolute;
    left: 26%;
    top: -138px;
    opacity: 0;
    z-index: 9999;
  }

  .message-left.animate-zoom {
    animation: zoomIn 3s ease-out;
    opacity: 1;
    transform-origin: bottom;
  }

  .message-left.message-left-back {
    animation: mesleftBack 3s ease-out;
    left: 20%;
    opacity: 1;
  }

  @keyframes mesrightBack {
    from {
      right: 25%;
    }

    to {
      right: 20%;
    }
  }

  .message-right {
    width: 40px;
    position: absolute;
    right: 25%;
    top: -140px;
    opacity: 0;
    z-index: 9999;
  }

  .message-right.animate-zoom {
    animation: zoomIn 3s ease-out;
    opacity: 1;
    transform-origin: bottom;
  }

  .message-right.message-right-back {
    animation: mesrightBack 3s ease-out;
    right: 20%;
    opacity: 1;
  }

  .stones {
    max-height: 10vh;
    position: absolute;
    bottom: -15%;
    z-index: 999;
  }

  .landing-bottom h6 {
    font-size: 24px;
    margin: 0 0 32px;
  }

}
@media screen and (max-width: 1300px) {
  .billing-plans {
    display: block;
    width: 100%;
  }
}

.fw-bold {
  margin-top: 26px;
}

.terms {
  position: fixed;
  bottom: 0;
  padding: 8px;
}

.page-item.active .page-link {
  background-color: black !important;
  border: none;
  color: white !important;
}

.page-link {
  color: black !important;
  margin: 6px;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
  outline: none !important;
  box-shadow: none !important;
  background-color: rgba(241, 241, 241, 1);
  border: none;
  padding: 4px;
}

.page-item.transparent-bg a {
  background-color: transparent !important;
}

a.page-link {
  border: none !important;
  font-weight: bold;
  font-size: 28px;
  padding-top: 0px;
  line-height: 32px;
}

.tree-block {
  display: contents;
}

.title {
  margin-bottom: 40px;
}

.vector,
.hero-board-mobile {
  display: none;
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}

.hero-board {
  display: block;
  z-index: 3;
  margin-top: 220px;
}

.bottom-block {
  display: contents;
}


@media screen and (min-width: 1064px) {
  .landing-center {
    margin-top: -250px;
  }
}

@media screen and (min-width: 1048px) {}

@media screen and (max-width: 1048px) {
  .stones {
    top: -20px;
  }
}

.heroboard-text {
  /*font-size: .8rem;*/
  font-size: 12px;
}

.heroboard-title {
  /*font-size: .9rem;*/
  font-size: 12px;
}

@media screen and (max-width: 585px) {
  .bottom-block {
    display: grid;
    position: fixed;
    bottom: 60px;

  }


  .skip-btn {
    display: contents;
  }

  .cta-headline {
    font-size: 18px;
  }

  .billing-plan-component {
    display: flex;
    flex-direction: column;
  }

  .invoices-block {
    width: 100%;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .invoices-table {
    background-color: white;
  }

  .invoice-subtitle {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .billing-plan-block {
    width: 100%;
    margin-bottom: 140px;
  }

  .billing-plan-block>h5 {
    text-align: center;
  }

  .invoices-details {
    width: 80vw;
  }

  .invoice-table-head,
  .invoice-row {
    border-bottom: none;
    width: 100%;
  }

  .card-number {
    font-size: 14px;
  }

  .invoice-row .col-2,
  .invoice-table-head .col-2 {
    min-width: 120px;
    padding: 8px;
  }

  .invoice-row .col-3,
  .invoice-table-head .col-3 {
    min-width: 250px;
    padding: 8px;
  }

  .invoice-table-head {
    font-size: 12px;
  }


  .invoice-row {
    font-size: 12px;
  }


  .account-top-bar {
    display: none;
  }

 
  .business-review-table {
    min-height: 50vh !important;
    max-height: 65vh !important;
    margin-bottom: 190px;
    min-width: 90vw !important;
    background-color: white;
  }

  .pagination-block {
    bottom: -100px !important;
  }

  .page-link {
    background-color: transparent !important;
    margin-right: 6px;
    border: none !important;
    font-weight: bold;
  }

  .rate-infoblock {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .account-rating {
    margin: 0;
    margin-bottom: 20px;
  }

  .review-row {
    font-size: 12px;
  }

  .table-head,
  .review-row {
    border-bottom: none;
    width: 100%;
  }

  .review-row .col,
  .table-head .col,
  .review-row .col-2,
  .table-head .col-2 {
    min-width: 140px;
    padding: 8px;
  }

  .table-content {
    overflow-y: hidden;
    background-color: white;
    min-width: 90vw;
  }

  .review-row .col-3,
  .table-head .col-3 {
    min-width: 250px;
    padding: 8px;
  }

  .table-head {
    font-size: 12px;
  }

  .table-content {
    max-width: 80vw;
  }

  .mobile-topbar-container {
    display: block;
    z-index: 99999;
  }

  .mobile-navbottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
    position: fixed;
    bottom: 0;
    z-index: 999;
  }

  .template-wrapper {
    display: flex;
    flex-direction: column;
  }

  .template-card {
    min-width: 80vw;
    margin-bottom: 20px;
  }

  .template-card>img {
    min-width: 80vw;
  }

  .template-card:nth-child(3) {
    margin-bottom: 120px;
  }

  .setting-toggle h1 {
    font-size: 16px !important;
    padding-top: 10px !important;
  }

  .setting-toggle img {
    width: 40px !important;
  }

  .setting-toggle>div {
    justify-content: center !important;
  }

  .setting-tab .custom-input {
    background-color: rgba(255, 255, 255, 1) !important;
  }

  .desktop,
  .hide {
    display: none;
  }

  .mobile {
    display: block;
  }

  .vector {
    display: block;
    position: absolute;
  }

  .poor-icon,
  .good-icon {
    /* height: 10vh; */
    /* min-height: auto; */
    width: 10vh;
  }

  .landing-client {
    min-height: 100dvh;    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0;
    overflow: hidden;
  }
  

  .landing-center-mobile {
    height: auto;
    width: 100vw;
    background-image: url('/src/assets/bg-main-desktop.svg');
    background-position: top;
    background-size: cover;
    background-repeat: repeat-x;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: bottom;
    min-height: 35dvh;
    background-repeat: repeat-x;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
 

  .hero-board {
    display: none;
  }

  .hero-board-mobile {
    display: block;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    
  }

  .landing-stat-block {
    padding: 7px 10px;
    margin-bottom: -10px;
  }

  .click-btn-title {
    padding: 0 8px;
  }

  .click-btn-title {}

  .title {
    position: absolute;
    top: -55px;
  }

  #hero-tree {
    position: absolute;
    bottom: -10%;
    z-index: 1;
    /* max-height: 40vh; */
    opacity: 0;
    object-position: bottom;
  }

  #hero-tree.animate-zoom {
    animation: zoomIn 3s ease-out;
    opacity: 1;
    transform-origin: bottom;
    margin-bottom: -50px;
    height: 38dvh;
  }

  @keyframes zoomIn {
    from {
      transform: scaleY(0);
      transform: scale(0);
      /* Начальный размер - 0% */
    }

    to {
      transform: scaleY(1);
      transform: scale(1);
      /* Конечный размер - 100% */
    }
  }

  @keyframes slideInBoy {
    from {
      left: -100%;
    }

    to {
      left: 50px;
    }
  }

  @keyframes reverseBoy {
    from {
      left: 10%;
    }

    to {
      left: 20px;
    }
  }

  #hero-boy {
    position: absolute;
    left: 50px;
    height: 80px;
    bottom: -55px;
    z-index: 999;
  }

  #hero-boy.slidein-boy {
    animation: slideInBoy 3s ease-out;
  }

  #hero-boy.reverse-boy {
    animation: reverseBoy 3s ease-out;
    left: 20px;
  }

  @keyframes slideInGirl {
    from {
      right: -100%;
    }

    to {
      right: 15%;
    }
  }

  @keyframes reverseGirl {
    from {
      right: 15%;
    }

    to {
      right: 10%;
    }
  }

  #hero-girl {
    position: absolute;
    right: 15%;
    height: 80px;
    bottom: -58px;
    z-index: 999;
  }

  #hero-girl.slidein-girl {
    animation: slideInGirl 3s ease-out;
  }

  #hero-girl.reverse-girl {
    animation: reverseGirl 3s ease-out;
    right: 10%;
  }

  .message-left {
    width: 40px;
    position: absolute;
    left: 18%;
    top: -7px;
    opacity: 0;
    z-index: 9999;
  }

  .message-left.animate-zoom {
    animation: zoomIn 3s ease-out;
    opacity: 1;
    transform-origin: bottom;
  }

  @keyframes mesleftBack {
    from {
      left: 18%;
    }

    to {
      left: 5%;
    }
  }

  @keyframes mesrightBack {
    from {
      right: 15%;
    }

    to {
      right: 5%;
    }
  }

  .message-left.message-left-back {
    animation: mesleftBack 3s ease-out;
    left: 5%;
    opacity: 1;
  }

  .message-right {
    width: 40px;
    position: absolute;
    right: 15%;
    top: -5px;
    opacity: 0;
    z-index: 9999;
  }

  .message-right.animate-zoom {
    animation: zoomIn 3s ease-out;
    opacity: 1;
    transform-origin: bottom;
  }

  .message-right.message-right-back {
    animation: mesrightBack 3s ease-out;
    right: 5%;
    opacity: 1;
    top: -5px;
  }

  .stones {
    position: relative;
    top: 77px;
    z-index: 999;
    height: 52px;
  }

  .landing-bottom h6 {
    font-size: 24px;
    margin: 0 0 12px;
    max-width: 80vw;
  }

  .sign-redirect .google-sign {
    width: 90%;
    display: flex;
    justify-content: center;
  }

  .topbar {
    height: 80px;
  }

  .landing-top {
    margin-top: 11px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }

  .anima-clouds {
    left: 0;
    top: -10dvh;
    animation: slideIn 8s ease-out;
    min-height: initial;
  }

  .fw-bold {
    margin-top: 5px;

  }

  .terms_mob{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}