.input-block {
    position: relative;
    margin-bottom: 16px;
}

/*.input-block_required input {*/
/*    position: relative;*/
/*}*/
.input-block_required:after {
    position: absolute;
    top: 24px;
    right: 10px;
    font-size: 16px;
    line-height: 6px;
    opacity: .8;
    content: '*';
}

.input-block_required input.invalid {
    height: 48px;
    border: 1px solid red;
}

.input-block_required textarea.invalid {
    border: 1px solid red;
}

.rating-block {
    padding-bottom: 14px;
}

.rating-block.invalid {
    border-bottom: 1px solid rgba(255, 0, 0 , .7);
}