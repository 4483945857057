#sidebar {
  min-width: 241px;
  box-shadow: 0 4px 40px #0000000f;
  padding: 38px 24px;
  
  @media screen and (max-width: 585px) {
    display: none;
  }
}

.sidebar-top {
  height: 15vh;
}

.account-nav-bottom {
  min-height: 20vh;
}

.nav-tab {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  padding: 18px 12px;
  border-radius: 16px;
  cursor: pointer;
}

.nav-tab-icon {
  width: 24px;
  margin-right: 16px;
}

.tab-active {
  background-color: black;
  color: white;
}
