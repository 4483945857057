.business-printable {
    min-height: 80vh;
    padding-bottom: 20px;
}

.template-wrapper {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 585px) {
    .business-printable {
        padding-bottom: 120px;
    }
}
