.create-progress-mobile {
    .hero-return {
        position: absolute;
        left: 0;
        margin-left: 10px;
        display: flex;
        align-items: center;
    }

    .route-item {
        cursor: pointer;
        font-size: 14px;
    }
}

.logout {
    position: absolute;
    display: flex;
    right: 0;
    margin-right: 10px;
    align-items: center;
    padding: 8px 20px;
    cursor: pointer;

    img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
}