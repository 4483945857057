@import "../../../../assets/styles/variables.scss";

.added-address {
    margin-top: 16px;

    .label {
        color: $gray;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        margin-bottom: 12px;
    }

    .address-block {
        display: flex;
        padding: 10px;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        justify-content: space-between;
        background-color: $mainBlue;

        .content {
            color: $mainLightGray;
            text-align: center;
            font-weight: 700;
            font-size: 12px;
        }

        svg {
            cursor: pointer;
        }
    }
}