.terms {
    width: 80%;
    margin: 80px auto;
    text-align: justify;

    .logo {
        display: inline-block;
        margin: 20px 0 50px;
    }

    & > .companyName {
        font-weight: 700;
    }

    & > .title {
        margin-bottom: 50px;
        font-weight: 700;
        text-decoration: underline;
        text-underline-position: under;
    }

    & .underlineSectionTitle {
        margin-bottom: 50px;
        font-weight: 700;
        text-decoration: underline;
        text-underline-position: under;
    }

    & > main > section {
        margin-bottom: 50px;
        & > p {
            &.sectionTitle {
                font-weight: 700;
            }
        }
    }

    & > .contact {
        margin-bottom: 50px;
    }

    & > .companyInfo {
        & > p {
            &:first-of-type {
                font-weight: 700;
            }            
        }        
    }

    & > .date {
        font-weight: 700;
        text-align: right;
    }
}