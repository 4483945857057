@import "../../../assets/styles/variables.scss";

.button {
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
}

.primary {
    background-color: $mainBlue;
    color: $white;
}

.secondary {
    background-color: #2ecc71;
    color: $white;
}

.link {
    background-color: transparent;
    color: $mainGreen;

    &:hover {
        text-decoration: underline;
    }
}

.btn-back {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 2px;
}
