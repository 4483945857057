.line {
    display: none;
}

@media screen and (max-width: 585px) {
    .links {
        padding: 0 20px;
        width: 100%;

        .bottom-links {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            background-color: #F1F1F1;
            font-size: 12px;
            padding: 20px 0 10px 0;
            border-top: 1px solid #D9D9D9;
        }
    
        .line {
            display: block;
            width: 100%;
            border-top: 1px solid #D9D9D9;
            height: 1px;
        }
    }

    .navbottom-tabs {
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: white;
        width: 100%;
        height: inherit;
        padding: 0 20px;

        .mobilenav-tab {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            background-color: white;
            font-size: 8px;
            width: 100%;
            text-align: center;
            height: 100% !important;
            padding: 12px;
            min-height: 76px;

            img {
                max-width: 24px;
                margin-bottom: 4px;
            }
        }

        .mobile-tab-active {
            background-color: black;
            color: white;
        }
    }
}
