@keyframes slideIn {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes zoomIn {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

.landingPageTopBlock {
    height: 37dvh;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../../assets/landing-client/backgroud.svg");
    background-position: left 25% bottom;
    margin-top: 80px;

    @media screen and (min-width: 390px) {
        height: 40dvh;
    }

    @media screen and (min-width: 440px) {
        height: 55dvh;
    }

    @media screen and (min-width: 585px) {
        margin-top: 79px;
        background-size: cover;
    }
    @media screen and (min-width: 768px) {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center bottom;
    }

    @media screen and (min-width: 1080px) {
        background-position: center bottom;
    }

    @media screen and (min-width: 1440px) {
        height: 59dvh;
        background-size: cover;
        background-position: center bottom;
    }
}

.landingPageBottom {
    position: relative;
    height: calc(63dvh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    
    @media screen and (max-width: 389px) and (min-height: 548px) and (max-height: 670px) {
        height: calc(62dvh - 80px);
    }

    @media screen and (min-width: 390px) {
        height: calc(60dvh - 80px);
    }
    @media screen and (min-width: 440px) {
        height: calc(45dvh - 80px);
    }
    @media screen and (min-width: 585px) {
    }
    @media screen and (min-width: 768px) {
        justify-content: flex-end;
    }
    @media screen and (min-width: 1440px) {
        height: calc(41dvh - 80px);
    }
}

.landingPageTreeWithChildren {
    width: 100vw;
    z-index: 1;
    display: flex;
    justify-content: center;
    bottom: calc(59dvh - 80px);
    position: absolute;
    @media screen and (min-width: 390px) {
        bottom: calc(56dvh - 80px);
        position: absolute;
    }
    @media screen and (min-width: 440px) {
        bottom: calc(39dvh - 80px);
    }
    @media screen and (min-width: 768px) and (min-height: 900px) and (max-height: 1080px)  {
        bottom: calc(38dvh - 80px);
    }
    @media screen and (min-width: 1080px) {
        bottom: calc(36dvh - 80px);
    }
    @media screen and (min-width: 1080px) and (min-height: 620px) and (max-height: 720px) {
        bottom: calc(42dvh - 80px);
    }
    @media screen and (min-width: 1440px) {
        bottom: calc(36dvh - 80px);
    }
    @media screen and (min-width: 1440px) and (max-height: 720px) {
        bottom: calc(39dvh - 80px);
    }
}

.landingPageInteractButtonsOuterContainer {
    position: absolute;
    top: calc(21dvh - 80px);
    @media screen and (min-width: 390px) {
        top: calc(22dvh - 80px);
    }
    @media screen and (min-width: 440px) {
        top: calc(17dvh - 80px);
    }    
    @media screen and (min-width: 768px) {
        top: calc(13dvh - 80px);
    }
    @media screen and (min-width: 768px) and (min-height: 900px) and (max-height: 1080px)  {
        top: calc(14dvh - 80px);
    }
    @media screen and (min-width: 1080px) {
        top: calc(16dvh - 80px);
    }
    @media screen and (min-width: 1080px) and (min-height: 620px) and (max-height: 720px) {
        top: calc(17dvh - 80px);
    }
    @media screen and (min-width: 1440px) {
        top: calc(12dvh - 80px);
    }
    @media screen and (min-width: 1440px) and (max-height: 720px) {
        top: calc(15dvh - 80px);
    }    
    @media screen and (min-width: 720px) and (max-height: 540px) {
        top: calc(18dvh - 80px);
        & > div:first-of-type > div {
            position: absolute;
            top: -160px;
        }
    }   
    @media screen and (min-width: 1024px) and (max-height: 600px) {
        top: calc(14dvh - 80px);
    }
    @media screen and (min-height: 1024px) {
        top: calc(12dvh - 80px);
    }
    @media screen and (min-height: 1180px) {
        top: calc(10dvh - 80px);
    }
}

.landingPageInteractButtonsContainer {
    padding-top: 24px;
    margin-bottom: 12px;
    & > p:last-of-type {
        margin-top: 20px;
        & > a:last-of-type {
            margin-left: 16px;
        }
    }
    @media screen and (min-width: 768px) {
        padding-top: 42px;
    }
    @media screen and (min-width: 720px) and (max-height: 540px) {
        & > p {
            font-size: 12px;
        }
        & svg {
            width: 40px;
            height: 40px;            
        }
    }
    @media screen and (min-width: 540px) and (max-height: 720px) {
        & svg {
            width: 54px;
            height: 54px;            
        }
    }
    @media screen and (min-width: 1024px) and (max-height: 600px) {
        & > p {
            font-size: 12px;
        }
        & svg {
            width: 60px;
            height: 60px;
            margin-bottom: 10px;
        }
    }
}

.landingPage {
    height: 100dvh;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100vw;
    position: relative;
    @media screen and (min-width: 440px) {
        height: 100dvh;
    }
}

.landingPageTopBarBlock {
    position: fixed;
    z-index: 7;
}

.landingPageClouds {
    width: 100vw;
    animation: slideIn 4s ease-out;
    position: absolute;
    left: 0;
    top: 15%;
    z-index: 0;
    scale: 3;

    @media screen and (min-width: 585px) {
        scale: unset;
        animation: slideIn 6s ease-out;
        min-height: initial;
    }

    @media screen and (min-width: 1440px){
        top: 8%;
    }
}

.landingPageBackgroundBlock {
    height: calc(100vh - 80px);
    position: relative;
    background-image: url("../../../assets/landing-client/backgroud.svg");
    background-size: auto 60%;
    background-repeat: no-repeat;
    background-position: left 25% bottom 40vh;

    @media screen and (min-width: 585px) {
        background-size: contain;
    }
    @media screen and (min-width: 768px) {
        background-repeat: no-repeat;
        background-position: center bottom 40vh;
    }

    @media screen and (min-width: 1080px) {
        background-position: center bottom 30vh;
    }

    @media screen and (min-width: 1440px) {
        background-size: cover;
        background-position: center bottom 25vh;
    }
}

.landingPageContainer {
    height: calc(100vh - 80px);
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.landingPageTop {
    position: relative;
    z-index: 2;
}

.landingPageTitle {
    position: relative;
    z-index: 1;
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    color: black;
    text-align: center;

    @media screen and (min-width: 585px) {
        font-size: 28px;
        line-height: 42px;
    }
    @media screen and (min-width: 1080px) {
        font-size: 46px;
        line-height: 48px;
    }
}

.landingPageStatsBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
    @media screen and (min-width: 768px) {
        justify-content: center;
        margin-top: 22px;
        margin-bottom: 10px;
        position: absolute;
    }
    @media screen and (min-width: 1440px) {
        margin-top: 14px;
    }
}

.treeBlock {
    width: fit-content;
    position: relative;
    display: flex;
    justify-content: center;
    @media screen and (min-width: 585px) {
        margin-top: -20px;
    }
}

.treeImgBlock {
    position: relative;
    width: 227px;
    height: 260px;
    @media screen and (min-width: 585px) {
        width: 302px;
        height: 349px;
    }
    @media screen and (min-width: 768px) {
        width: 320px;
        height: 363px;
    }
    @media screen and (min-width: 1080px) {
        width: 340px;
        height: 390px;
    }
}

.treeImg {
    opacity: 0;
    width: 227px;
    @media screen and (min-width: 585px) {
        width: 302px;
    }
    @media screen and (min-width: 768px) {
        width: 320px;
    }
    @media screen and (min-width: 1080px) {
        width: 340px;
    }
}

.treeImgAnimate {
    animation: zoomIn 3s ease-out;
    opacity: 1;
    transform-origin: bottom;
    height: 260px;

    @media screen and (min-width: 585px) {
        height: 349px;
    }
    @media screen and (min-width: 768px) {
        height: 363px;
    }
    @media screen and (min-width: 1080px) {
        height: 390px;
    }
}

.treeGlassImg {
    position: absolute;
    bottom: 0;
    left: calc(50% - 45px);
    width: 97px;
    @media screen and (min-width: 768px) {
        left: calc(50% - 50px);
        width: 114px;
    }
}

.children {
    position: absolute;
    width: 85%;
    height: 150px;
    padding: 0 15px 0 0;
    bottom: -5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media screen and (min-width: 768px) {
        padding: 0 30px 0 0;
    }
}

.landingPageBoyWrapper {
    position: relative;
    width: 83px;
    height: 90px;

    @media screen and (min-width: 768px) {
        width: 138px;
        height: 151px;
    }
}

.landingPageBoy {
    position: absolute;
    left: 0;
    top: 0;
}

.landingPageBoy_Revers {
    @keyframes reverseBoy {
        from {
            left: 20px;
        }
        to {
            left: 0;
        }
    }
    animation: reverseBoy 3s ease-out;
    left: 0;

    @media screen and (min-width: 585px) {
        @keyframes reverseBoy {
            from {
                left: 55px;
            }
            to {
                left: 0;
            }
        }
    }
    @media screen and (min-width: 768px) {
        @keyframes reverseBoy {
            from {
                left: 0;
            }
            to {
                left: -30px;
            }
        }
        left: -30px;
    }
    @media screen and (min-width: 1080px) {
        @keyframes reverseBoy {
            from {
                left: 20px;
            }
            to {
                left: -30px;
            }
        }
    }
}

.landingPageBoy_SlideIn {
    @keyframes slideInBoy {
        from {
            left: -40vw;
        }
        to {
            left: 20px;
        }
    }
    animation: slideInBoy 3s ease-out;
    left: 20px;

    @media screen and (min-width: 585px) {
        @keyframes slideInBoy {
            from {
                left: -40vw;
            }
            to {
                left: 55px;
            }
        }
        left: 55px;
    }
    @media screen and (min-width: 768px) {
        @keyframes slideInBoy {
            from {
                left: -60vw;
            }
            to {
                left: 0;
            }
        }
        left: 0;
    }
    @media screen and (min-width: 1080px) {
        @keyframes slideInBoy {
            from {
                left: -80vw;
            }
            to {
                left: 20px;
            }
        }
        left: 20px;
    }
}

.landingPageGirlWrapper {
    position: relative;
    width: 55px;
    height: 91px;

    @media screen and (min-width: 768px) {
        width: 94px;
        height: 152px;
    }
}

.landingPageGirl {
    position: absolute;
    right: 0;
    top: 0;
}

.landingPageGirl_Revers {
    @keyframes reverseGirl {
        from {
            right: 20px;
        }
        to {
            right: 0;
        }
    }
    animation: reverseGirl 3s ease-out;
    right: 0;

    @media screen and (min-width: 585px) {
        @keyframes reverseGirl {
            from {
                right: 55px;
            }
            to {
                right: 0;
            }
        }
    }
    @media screen and (min-width: 768px) {
        @keyframes reverseGirl {
            from {
                right: 0;
            }
            to {
                right: -30px;
            }
        }
        right: -30px;
    }
    @media screen and (min-width: 1080px) {
        @keyframes reverseGirl {
            from {
                right: 20px;
            }
            to {
                right: -30px;
            }
        }
    }
}

.landingPageGirl_SlideIn {
    @keyframes slideInGirl {
        from {
            right: -40vw;
        }
        to {
            right: 20px;
        }
    }
    animation: slideInGirl 3s ease-out;
    right: 20px;

    @media screen and (min-width: 585px) {
        @keyframes slideInGirl {
            from {
                right: -40vw;
            }
            to {
                right: 55px;
            }
        }
        right: 55px;
    }
    @media screen and (min-width: 768px) {
        @keyframes slideInGirl {
            from {
                right: -60vw;
            }
            to {
                right: 0;
            }
        }
        right: 0;
    }
    @media screen and (min-width: 1080px) {
        @keyframes slideInGirl {
            from {
                right: -80vw;
            }
            to {
                right: 20px;
            }
        }
        right: 20px;
    }
}

.landingPageLeftMessage {
    width: 41px;
    position: absolute;
    right: 34px;
    top: -36px;
    opacity: 0;

    @media screen and (min-width: 585px) {
        right: 0;
        opacity: 0;
    }
    @media screen and (min-width: 768px) {
        right: unset;
        left: 5px;
    }
    @media screen and (min-width: 1080px) {
        left: 23px;
    }
}

.landingPageLeftMessage_AnimateZoomIn {
    animation: zoomIn 3s ease-out;
    opacity: 1;
    transform-origin: bottom;
}

.landingPageLeftMessage_AnimateBack {
    @keyframes msgLeftBack {
        from {
            right: 34px;
        }
        to {
            right: 54px;
        }
    }
    animation: msgLeftBack 3s ease-out;
    right: 54px;
    opacity: 1;

    @media screen and (min-width: 585px) {
        @keyframes msgLeftBack {
            from {
                right: 0;
            }
            to {
                right: 54px;
            }
        }
    }
    @media screen and (min-width: 768px) {
        @keyframes msgLeftBack {
            from {
                left: 5px;
            }
            to {
                left: -20px;
            }
        }
        right: unset;
        left: -20px;
    }
    @media screen and (min-width: 1080px) {
        @keyframes msgLeftBack {
            from {
                left: 23px;
            }
            to {
                left: -25px;
            }
        }
        left: -25px;
    }
}

.landingPageRightMessage {
    width: 41px;
    position: absolute;
    left: 20px;
    top: -35px;
    opacity: 0;

    @media screen and (min-width: 585px) {
        left: -16px;
        opacity: 0;
    }
    @media screen and (min-width: 768px) {
        left: 65px;
        opacity: 0;
    }
    @media screen and (min-width: 1080px) {
        left: 50px;
        opacity: 0;
    }
}

.landingPageRightMessage_AnimateZoomIn {
    animation: zoomIn 3s ease-out;
    opacity: 1;
    transform-origin: bottom;
}

.landingPageRightMessage_AnimateBack {
    @keyframes msgRightBack {
        from {
            left: 20px;
        }
        to {
            left: 45px;
        }
    }
    animation: msgRightBack 3s ease-out;
    left: 45px;
    opacity: 1;

    @media screen and (min-width: 585px) {
        @keyframes msgRightBack {
            from {
                left: -16px;
            }
            to {
                left: 41px;
            }
        }
        left: 41px;
    }
    @media screen and (min-width: 768px) {
        @keyframes msgRightBack {
            from {
                left: 65px;
            }
            to {
                left: 100px;
            }
        }
        left: 100px;
    }
    @media screen and (min-width: 1080px) {
        @keyframes msgRightBack {
            from {
                left: 50px;
            }
            to {
                left: 100px;
            }
        }
        left: 100px;
    }
}

.landingPageButtonsTitle {
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    padding: 0 16px;
    margin: 4px 0 6px;
    @media screen and (min-width: 768px) {
        font-size: 16px;
        line-height: 18px;
        margin: 4px 0 16px;
    }
}

.landingPageButtonsContainer {
    display: flex;
    justify-content: center;
}

.iconContainer {
    text-align: center;
    & > span {
        display: block;
        margin-top: 4px;
        font-weight: 700;
        font-size: 12px;
    }
}

.poorIcon, .goodIcon {
    cursor: pointer;
    &Container {
        @extend .iconContainer;    
    }    
}

.poorIcon {
    &Container {
        & > span {
            color: rgba(242, 86, 116, 1);
        }
    }
}

.goodIcon {
    &Container {
        margin-left: 76px;
        & > span {
            color: rgba(89, 200, 87, 1);
        }
    }
}

.landingPageTerms {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 0;
    line-height: 8px;
    display: flex;
    justify-content: center;
}
