.signRedirect {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    & > h1 {
        margin-bottom: 40px;
        font-size: 24px;
        font-weight: bold;
        color: #000000;
    }
    & img {
        margin-bottom: 16px;
    }    
    & p {
        max-width: 400px;
        text-align: center;
        color: var(--grey-font);
    }
    & > input {
        border: none;
        padding: 12px;
        background-color: #F1F1F1;
        width: 100%;
        margin-bottom: 16px;
        border-radius: 8px;
        outline: none;
        max-width: 350px;
    }  
}
.googleSign {
    max-width: 350px;
    width: 90%;
    display: flex;
    align-items: center;
    text-decoration: none;
    background-color: var(--main-green);
    color: white !important;
    border: 1px solid var(--main-green);
    border-radius: 4px;
    padding: 8px 20px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 20px;
    &:hover {
        color: white !important;
        background-color: #59c857ce;
        transition: all 0.15s ease-in-out;
    }
    img {
        margin-right: 16px;
        margin-bottom: 0;
    }

    @media screen and (max-width: 1024px) {
        width: 90%;
        display: flex;
        justify-content: center;
    }
}
.bottomContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    & > button {
        width: 90%;
        max-width: 350px;
    }
}