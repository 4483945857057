.reviewTable {
    &Modal {
        &Content {
            max-height: 50vh;            
            &Top {
                text-align: center;
            }
            &Date {
                font-size: 10px;
            }
            &Name {
                margin-top: 8px;
                font-size: 12px;
                font-weight: 700;
                color: var(--grey-font);
                & > span {
                    &:last-of-type {
                        margin-left: 4px;
                    }
                }
            }
            &Title {
                margin-top: 4px;
                font-size: 24px;
                font-weight: 600;
            }
            &Rating {
                & > img {
                    max-width: 12px;
                    &:not(:first-of-type) {
                        margin-left: 4px;
                    }
                }
            }
            &Review {
                max-height: 300px;
                overflow-y: auto;
                margin-top: 42px;
                padding-right: 16px;
            }
        }
    }
}
.reviewModal {    
    &Close {
        position: absolute !important;
        right: 8px;
        top: 8px;            
    }
    &Content {
        padding: 40px 32px !important;
    }
}
.pageLink {
    cursor: pointer;
    color: black;
    margin: 6px;
    border-radius: 6px;
    width: 32px;
    height: 32px;
    text-align: center;
    outline: none;
    box-shadow: none;
    background-color: rgba(241, 241, 241, 1);
    border: none;
    padding: 4px;
    &Active {
        background-color: black;        
        color: white;
    }
}
.overflowEllipsis {    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}