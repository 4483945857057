.copy-confirmation {
  position: absolute;
  top: -20px;
  left: 20px;
  animation: fadeOut 2s forwards;
  color: green;
  font-size: 14px;
  margin-top: 5px;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.dashboard {
  min-height: 100vh;
  display: flex;

  @media screen and (max-width: 585px) {
    min-height: 100vh;
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: #f1f1f1;
  }
}

.dashboard-container {
  width: 100%;
  overflow-x: scroll;

  .header {
    & > .welcome-message {
        margin-top: 20px;
        text-align: center;

      & > span {
        display: block;
        margin: auto;
        &:first-of-type {
          font-weight: 700;
          font-size: 48px;
        }
        &:last-of-type {
          margin-top: 20px; 
          margin-bottom: 60px;
          max-width: 70%;
          font-size: 24px;
        }
      }
    }

    .viewing-block {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      margin: 20px 20px 0 0;
      color: gray;

      .viewing {
        width: 63px;
        height: 63px;
        font-weight: 600;
        text-decoration: none;
        background-color: #F1F1F1;
        border-radius: 50%;
        display: flex;
        align-items: center;
        font-size: small;
        justify-content: center;
        color: gray;
      }

      svg {
        margin-top: 12px;
      }
    }
  }

  & > .dashboard-inner-container {
      width: 80%;
      margin: auto;
      box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.06);
  }

  @media screen and (max-width: 585px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
